import React from "react";
import { Link } from "gatsby";

const Menu = ({ input }) => {
  /**
   * back to ressources grid if inside modal
   */
  const _onClick = (uid) => {
    const { pathname } = location;

    if (!pathname) return;
    if (uid !== "ressources") return;

    if (pathname.replace("/", "") === uid) location.reload();
  };

  return (
    <nav className="menu-header">
      <ul className="">
        {input.map((li, i) => (
          <li key={i} className="menu-item">
            <Link to={`/${li.item.uid}`} onClick={() => _onClick(li.item.uid)}>
              {li.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
