import React, { useState } from "react";
import clsx from "clsx";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  /* min-width: var(--margeL); */
  /* justify-content: center; */
  padding: 0 var(--margeM);
  &.tal {
    padding-left: 0;
    justify-content: flex-start;
  }
  @media all and (max-width: 1024px) {
    padding: 0 var(--margeS);
    padding: 0;
    /* min-width: 1em; */
  }
  &.is-active {
    min-width: unset;
  }
  &.is-active.tal {
    justify-content: flex-start;
  }
  &.is-active.tar {
    justify-content: flex-end;
  }
`;

const Word = styled.div`
  display: flex;
  .is-active & {
    margin-right: 0.3em;
  }
  &:last-child {
    margin-right: 0;
  }
`;
const Leftover = styled.div.attrs((/* props */) => ({ tabIndex: 0 }))`
  // transition: max-width 0.25s;
  /* overflow: hidden; */
  /* max-width: 0; */
  /* display: block; */
  display: none;
  .is-active & {
    /* max-width: 3.375em; */
    display: block;
  }
`;

const AccronymToText = ({ align, text }) => {
  const [active, setActive] = useState(false);

  const words = text.split(" ");

  const getAccr = (source) => source.substring(0, 1);
  const getLeftover = (source) => source.substring(1, source.length);

  return (
    <Container
      className={clsx(
        "accr-to-text ",
        align === "left" ? "tal" : "tar",
        active && "is-active"
      )}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      {words.map((el, i) => (
        <Word key={i}>
          <span className="accr">{getAccr(el)}</span>
          <Leftover className="leftover">{getLeftover(el)}</Leftover>
        </Word>
      ))}
    </Container>
  );
};

export default AccronymToText;
