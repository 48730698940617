import React, { useEffect, useState } from "react";
import PubSub from "pubsub-js";
import clsx from "clsx";
import styled from "styled-components";

const Li = styled.li`
  padding-right: 0.5em;
  cursor: pointer;
  &.is-active {
    text-decoration: underline;
  }
`;

const MenuProjets = () => {
  const [displayType, setDisplayType] = useState("image");

  useEffect(() => {
    PubSub.publish("DISPLAY_TYPE", displayType);
  }, [displayType]);

  return (
    <ul className="x">
      <Li
        onClick={() => setDisplayType("image")}
        className={clsx(displayType === "image" ? "is-active" : "")}
      >
        IMAGE
      </Li>
      <Li
        onClick={() => setDisplayType("liste")}
        className={clsx(displayType === "liste" ? "is-active" : "")}
      >
        LISTE
      </Li>
    </ul>
  );
};

export default MenuProjets;
