// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-template-page-jsx": () => import("./../../../src/templates/template-page.jsx" /* webpackChunkName: "component---src-templates-template-page-jsx" */),
  "component---src-templates-template-single-projet-jsx": () => import("./../../../src/templates/template-single-projet.jsx" /* webpackChunkName: "component---src-templates-template-single-projet-jsx" */)
}

