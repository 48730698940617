import React, { useContext, useState } from "react";
//import { StaticQuery, graphql } from "gatsby"
import { useStaticQuery, graphql } from "gatsby";
import LinkFade from "./ui/TransitionLinks/LinkFade";
import { WrapperContext } from "./Layout";
import { RichText } from "prismic-reactjs";
import Menu from "./Menu";
import MenuProjets from "./MenuProjets";
// import Burger from "./ui/Burger";
import AccronymToText from "./ui/AccronymToText";
import clsx from "clsx";

const query = graphql`
  query {
    prismicMenu(uid: { eq: "menu-header" }) {
      data {
        menu_items {
          item {
            uid
          }
          title
          accronyme
        }
      }
    }
  }
`;

const Header = ({ isMobile }) => {
  const { settings, template } = useContext(WrapperContext);
  const { prismicMenu } = useStaticQuery(query);

  const [contactActive, setContactActive] = useState(false);

  const menuItemWithAccronyme = prismicMenu.data.menu_items.filter(
    (el) => el.accronyme
  );
  // const menuItems = prismicMenu.data.menu_items.filter((el) => !el.accronyme);

  const [collapse, setCollapse] = useState(true);
  // console.log({ collapse });

  const _handleCollapse = (val) => {
    if (isMobile) return;
    setCollapse(val);
  };

  return (
    <header className="fL">
      <div className="inner">
        <div
          className="offset left py-m"
          onClick={() => setCollapse(!collapse)}
          onMouseEnter={() => _handleCollapse(false)}
        >
          <div
            className={clsx("btn-toggle", collapse ? "" : "is-active")}
          ></div>
        </div>
        <div className="">
          <div className="row gutter-m">
            <div className="col-xs-6">
              <div
                className={clsx(
                  "menu-wrapper py-m pl-l",
                  collapse ? "is-collapsed" : ""
                )}
                onMouseLeave={() => _handleCollapse(true)}
              >
                <Menu input={prismicMenu.data.menu_items} />
              </div>
            </div>

            <div className="col-xs-6">
              <div
                className={clsx(
                  "contact py-m",
                  contactActive ? "is-active" : ""
                )}
                onMouseEnter={() => setContactActive(true)}
                onMouseLeave={() => setContactActive(false)}
              >
                <div className="x xje md:xjb">
                  <div className="accr-atelier">
                    <AccronymToText
                      text={menuItemWithAccronyme[0].title}
                      align="left"
                    />
                  </div>

                  <div className="description-wrapper">
                    <AccronymToText text="JULIEN BOIDOT" align="right" />
                    <div className="description tar">
                      {RichText.render(settings.data.description.raw)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="offset right">

        </div> */}
      </div>
    </header>
  );
};

export default Header;
