import React, { ReactNode, useCallback } from "react";
import TransitionLink from "gatsby-plugin-transition-link";
import { Link } from "gatsby";

export const LinkFade = ({ children, to }) => {
  const exitTransition = {
    length: 0.4,
    zIndex: 2,
    trigger: ({ node }) => {
      exitTransition.exitTrigger(node);
      if (node) node.style.top = -window.pageYOffset + "px";
      window.scrollTo({ top: -window.pageYOffset });
    },
    exitTrigger: useCallback((container) => {
      container.setAttribute("style", "animation: fadeOut .4s ease forwards;");
    }, []),
  };

  const entryTransition = {
    // appearAfter: 2,
    // length: 1,
    length: 0.4,
    delay: 1,
    zIndex: 1,
    trigger: ({ node }) => {
      entryTransition.entryTrigger(node);
    },
    entryTrigger: useCallback((container) => {
      container.setAttribute("style", "animation: fadeIn .4s ease forwards;");
    }, []),
  };

  return (
    <>
      {/* <TransitionLink to={to} exit={exitTransition} entry={entryTransition}>
        {children}
      </TransitionLink> */}
      <Link to={to}>{children}</Link>
      {/* <a href={to}>{children}</a> */}
    </>
  );
};

export default LinkFade;
