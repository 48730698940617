import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./Header";
import { subscribe, unsubscribe } from "pubsub-js";
// import Footer from "./Footer"

const WrapperContext = React.createContext();

const query = graphql`
  {
    prismicSettings {
      ...settings
    }
  }
`;

const Layout = ({ children, pageContext: { template } }) => {
  console.log("------ Layout template", template);

  const { prismicSettings: settings } = useStaticQuery(query);

  const [isMobile, setIsMobile] = useState(undefined);

  useEffect(() => {
    _format();

    const token = subscribe("ROUTE_UPDATE", () => {
      document.documentElement.classList.remove("no-scroll");
      _format();
    });

    window.addEventListener("resize", _format);
    window.addEventListener("scroll", _format);
    if (window.innerWidth < 1080 && "ontouchstart" in window) setIsMobile(true);

    return () => {
      unsubscribe(token);
      window.removeEventListener("resize", _format);
      window.removeEventListener("scroll", _format);
    };
  }, []);

  const _format = () => {
    // console.log("format");
    // const ww = window.innerWidth;
    // const wh = window.innerHeight;
    document.documentElement.style.setProperty(
      "--appHeight",
      window.innerHeight + "px"
    );
    const vh = window.innerHeight * 0.01;
    // const vh = window.innerHeight * 0.1;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const headerBounding = document
      .querySelector("header")
      .getBoundingClientRect();
    // if (headerBounding.height > 56) headerBounding.height = 56;

    document.documentElement.style.setProperty(
      "--headerHeight",
      headerBounding.height + "px"
    );

    // _renderDesignSystemVars();
  };

  // const _renderDesignSystemVars = () => {
  //   const wrapper = document.querySelector("#d-s");
  //   const md6 = wrapper.querySelector(".col-md-6");
  //   const md4 = wrapper.querySelector(".col-md-4");
  //   const md3 = wrapper.querySelector(".col-md-3");
  //   const md2 = wrapper.querySelector(".col-md-2");
  //   const md1 = wrapper.querySelector(".col-md-1");
  // };

  return (
    <WrapperContext.Provider value={{ settings, template, isMobile }}>
      <div id="page">
        {/* <div id="d-s" className="d-s px-l">
          <div className="row">
            <div className="col-md-6">6</div>
            <div className="col-md-4">4</div>
            <div className="col-md-3">3</div>
            <div className="col-md-2">2</div>
            <div className="col-md-1">1</div>
          </div>
        </div> */}
        <Header isMobile={isMobile} />
        <main>{children}</main>
      </div>
    </WrapperContext.Provider>
  );
};

export { WrapperContext, Layout };
